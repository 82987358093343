* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.root {
    max-width: 100vw;
    max-height: 100vh;
}

body {
    background-color: #000;
    color: #fff;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
}

@keyframes fill-ani {
    0% {
        opacity: .75;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: .75;
    }
}

.background {
    display: flex;
    position: absolute;
    top: 0;
    z-index: -1;
    justify-content: center;
    align-items: center;
    width: max(100vw, 100vh);
}

.container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    border: 1px solid rgb(46, 167, 223);
    box-shadow: 5px 5px 10px #00000099;
    margin: 2.5vh 5vh;
    text-align: center;
    height: 95vh;
}

.container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    width: 100%;
    border: 1px solid rgb(46, 167, 223);
    border-radius: 1em;
    margin: .5em;
}

.pregunta-container {
    margin-bottom: 1vw;
}

.pregunta {
    display: block;
}

.respuestas-container {
    width: 100%;
    padding: 2vw;
}

.loading {
    border: 0;
    margin-bottom: 0;
}

.data-container {
    right: 0;
    padding: 5vw 0;
    width: calc(100vw - ((min(65vh, 50vw) + 1em)*.95) - 12.5vh);
}

.option {
    cursor: pointer;
    display: block;
    width: calc(50% - 1em);
    float: left;
    transition: all .2s ease-in-out;
}

.option:hover {
    transform: scale(1.05);
}

.score {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    position: absolute;
    top: 0px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding-left: 25%;
    padding-right: 25%;
    border-bottom: 1px solid rgb(46, 167, 223);
}

.capcolmil {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    bottom: 0;
    padding: 1.2em;
    border-top: 1px solid rgb(46, 167, 223);
    width: 100%;
}

.correct-option {
    border-color: rgba(68, 212, 32, 0.8);
    background-color: rgba(53, 143, 31, .8);
    transform: scale(1.05);
}

.incorrect-option {
    border-color: rgba(255, 0, 0, 0.8);
    background-color: rgba(187, 17, 17, .8);
}

.correct {
    color: rgb(53, 143, 31);
    font-size: 1.2em;
}

.incorrect {
    color: rgb(187, 17, 17);
    font-size: 1.2em;
}

.fs-button {
    border-radius: 0 0 0 50%;
    border-left: 1px solid rgb(46, 167, 223);
    border-bottom: 1px solid rgb(46, 167, 223);
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
}

.mapa-container {    
    max-width: calc((min(65vh, 50vw) + 20px)*.95);
}

.map-default {
    fill:transparent;
    fill-opacity:1;
    fill-rule:evenodd;
    stroke:rgb(46, 167, 223, .5);
    stroke-width:.5;
    stroke-linecap:butt;
    stroke-linejoin:miter;
    stroke-miterlimit:4;
    stroke-dasharray:none;
    stroke-opacity:1;
    transition: all 1s;
}

.map-active {
    fill: #1454B3;
    stroke: rgb(46, 167, 223);
    stroke-width: .8;
    animation: fill-ani 2s ease-in-out infinite;
}

.map-not-active {
}

.map-correct {
    fill: #1792329f;
    stroke: rgb(23, 255, 15);
    stroke-width: .8;
}

.map-incorrect {
    fill: #861a1a7c;
    stroke: rgba(255, 0, 0, 0.658);
    stroke-width: .8;
}